import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js"
import { English } from "flatpickr/dist/l10n/default.js";
import Choices from "choices.js";
import BSN from "bootstrap.native";
import { getMetaValue } from "helpers";
import { ApplicationController } from "../application_controller";

const TABLE_ID = "deal_hotels_form_table"
const TAB_ID = "deal_services_hotels"

export default class extends ApplicationController {
  static targets = [
    "main_form",
    "service_tr",
    "choices_supplier",
    "choices_service",
    "add_popover",
    "price_dropdown",
    "daterange_period"
  ]

  connect() {
    if (this.hasChoices_supplierTarget) this.initSupplierChoices();
    if (this.hasChoices_serviceTarget) this.initServiceChoices();
    if (this.hasMain_formTarget) this.setFormTotalHtml();
    if (this.hasDaterange_periodTarget) {
      //
      // set locale
      //
      let app_locale = document.querySelector('body').dataset.lang;
      let locales = {
        "ru": {
          locales: Russian,
          date_format: "d-m-Y",
          datetime_format: "d-m-Y H:i"
        },
        "en": {
          locales: English,
          date_format: "Y-m-d",
          datetime_format: "Y-m-d H:i"
        }
      }

      //
      // locale Options
      //
      const locale_options = locales[app_locale];

      this.daterange_period(this.daterange_periodTarget, locale_options);
    }
  }

  //
  // Actions
  //
  collapseServices(event) {
    event.preventDefault();
    let target = event.currentTarget;
    let collapse = new BSN.Collapse(target)
    if (target.classList.contains("collapsed"))
      collapse.show();
    else collapse.hide();
  }


  // add selected service
  async addSelectedService(event) {
    event.preventDefault();
    this.removeAddPopover();
    const btn_target = event.currentTarget
    const url = btn_target.getAttribute("data-url")
    if (!btn_target.classList.contains("disabled")) {
      await this.addServiceRequest(url)
      this.enableDisableSaveButtons()
    }
  }

  openModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalXL(url);
  }

  statusChange(event) {
    const tr = event.currentTarget.closest("tr");
    this.setTrTotalHtml(tr);
    this.setFormTotalHtml();
  }

  priceChange(event) {
    const tr = event.currentTarget.closest("tr");
    this.setTrTotalHtml(tr);
    this.setFormTotalHtml();
  }

  countChange(event) {
    const tr = event.currentTarget.closest("tr");
    this.setTrTotalHtml(tr);
    this.setFormTotalHtml();
  }

  repeatCountChange(event) {
    const tr = event.currentTarget.closest("tr");
    this.setTrTotalHtml(tr);
    this.setFormTotalHtml();
  }

  removeService(event) {
    event.stopImmediatePropagation();
    if (confirm(event.currentTarget.getAttribute("data-confirm-message"))) {
      const tr = event.currentTarget.closest("tr");
      let id = event.currentTarget.getAttribute("data-id")
      if (id) {
        let destroy_target = document.getElementById(id)
        if (destroy_target) destroy_target.value = true
      }
      tr.remove();
      this.reindexTableRows();
      this.setFormTotalHtml();
      this.enableDisableSaveButtons()
    }
  }

  priceOpen(event) {
    event.preventDefault();
    const price_dropdown = event.currentTarget;
    if (price_dropdown.dropdown) {
      price_dropdown.dropdown.toggle();
    }
    else {
      price_dropdown.dropdown = new BSN.Dropdown(price_dropdown)
      price_dropdown.dropdown.toggle();

      price_dropdown.parentNode.addEventListener('hide.bs.dropdown', function (e) {

        const price_target = price_dropdown.closest(".hotel_price_modal").querySelector("input.price_input");
        let manual_value = parseFloat(price_target.numeric.rawValue.replace(",", '.').replace(/[^\d.-]/g, ''))
        manual_value = manual_value || 0
        let default_value = parseFloat(price_target.getAttribute("data-default-value").replace(",", '.').replace(/[^\d.-]/g, ''))

        if (manual_value <= 0 || default_value !== manual_value)
          price_target.numeric.set(default_value)

      }, false);

    }
  }

  priceCloseNotSet(event) {
    event.preventDefault();
    const price_dropdown = event.currentTarget.closest(".hotel_price_modal").querySelector("a.price_dropdown_open");
    if (price_dropdown.dropdown) price_dropdown.dropdown.dispose();
  }

  priceCloseAndSet(event) {
    event.preventDefault();
    const price_dropdown = event.currentTarget.closest(".hotel_price_modal").querySelector("a.price_dropdown_open");
    const price_target = event.currentTarget.closest(".hotel_price_modal").querySelector("input.price_input");
    const extra_price_target = event.currentTarget.closest(".hotel_price_modal").querySelector("input.extra_price_input");
    const price_span = event.currentTarget.closest(".hotel_price_modal").querySelector("span.price_color");
    const tr_row = event.currentTarget.closest("tr")

    let checked_currency = event.currentTarget.closest(".hotel_price_modal").querySelector("input[data-target='deals--hotels.ticket_tr_currency']:checked");
    let manual_value = parseFloat(price_target.value.replace(",", '.').replace(/[^\d.-]/g, ''))
    let extra_price_value = parseFloat(extra_price_target.value.replace(",", '.').replace(/[^\d.-]/g, ''))
    if (manual_value >= 0) {
      price_target.setAttribute("data-default-value", manual_value)
      const tr = event.currentTarget.closest("tr");

      if (manual_value > 0 || extra_price_value > 0)
        price_span.classList.remove("font-danger")
      else
        price_span.classList.add("font-danger")

      const is_manual_price_target = event.currentTarget.closest(".hotel_price_modal").querySelector("input.is_manual_price_input");

      if (price_dropdown.dropdown) price_dropdown.dropdown.dispose();
      if (is_manual_price_target) {
        is_manual_price_target.value = true
        tr_row.setAttribute('data-service-currency-to-main-rate', parseFloat(checked_currency.dataset['rateToMain']))
        tr_row.setAttribute('data-service-currency-to-additional-rate', parseFloat(checked_currency.dataset['rateToAdditional']))
      }

      this.setTrTotalHtml(tr);
      this.setFormTotalHtml();
    }

  }

  priceNotCloseClick(event) {
    event.stopPropagation();
  }

  changeCurrency(event) {
    const modal_body = event.currentTarget.closest(".modal-body");
    const currency = event.currentTarget.getAttribute("value");
    const modal_bodyCurrencyTexts = modal_body.querySelectorAll(".modal_currency_text");
    modal_bodyCurrencyTexts.forEach(el => {
      el.innerHTML = currency;
    });
    event.preventDefault();
  }

  reloadServicesPrices(event) {
    event.preventDefault();
    const btn_target = event.currentTarget
    const url = btn_target.getAttribute("data-url")
    const message = btn_target.getAttribute("data-confirm-message")

    if (confirm(message)) {
      this.reloadServiceRequest(url);
    }
  }

  reloadServiceRow(event) {
    event.preventDefault();

    const btn_target = event.currentTarget;
    btn_target.setAttribute("disabled", "disabled");

    const url = btn_target.getAttribute("data-url")
    const tr = event.currentTarget.closest("tr");
    let id = tr.getAttribute("data-service-id")

    const data = new FormData();

    if (id) {
      data.append("id", id)
    }

    tr.querySelectorAll("input").forEach( input => {
      data.append(input.name, input.value);
    })

    tr.querySelectorAll("select").forEach( sel => {
      data.append(sel.name, sel.choices.getValue(true));
    })

    tr.querySelectorAll("textarea").forEach( area => {
      data.append(area.name, area.value);
    })

    this.reloadServiceRowRequest(url, data, tr)
  }

  onPeriodChange(event) {
    const tr = event.currentTarget.closest("tr");
    const data = new FormData();
    tr.querySelectorAll("input").forEach( input => {
      data.append(input.name, input.value);
    })

     fetch('/dashboard', {
        body: data,
        method: "POST",
        dataType: "text/html",
        credentials: "include",
        headers: {
          "X-CSRF-Token": getMetaValue("csrf-token")
        }
      })
  }


  onPostPutSuccess(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      // show notify
      window.vNotify.success({ text: data.notification_message, title: data.notification_title });
      // update tab data
      let extraSupplierBody = document.getElementById(TAB_ID)
      if (extraSupplierBody) extraSupplierBody.innerHTML = data.table_content
      // hide form modal
      if (data.save_and_new === true || data.save_and_new === "true") {
        // modal set content
        window.layout_modal_xl.setContent(data.form_content)
      } else {
        window.layout_modal_xl.hide();
      }
    }
  }

  // update deal service form
  onPostPutError(event) {
    const [data, status, xhr] = event.detail;
    // modal set content
    window.layout_modal_xl.setContent(data.form_content)
  }


  //
  // private
  //

  //
  // enable_disable buttons
  //
  enableDisableSaveButtons() {
    const save_and_new_btn = document.getElementById("hotels_save_and_new")
    const save_btn = document.getElementById("hotels_save")
    const empty_form_info = document.getElementById("hotels_empty_form_info")
    const table = document.getElementById(TABLE_ID)
    const rows = table.tBodies[0].querySelectorAll("tr:not(.hidden)")
    if (rows.length > 0) {
      if (save_and_new_btn) save_and_new_btn.removeAttribute("disabled")
      save_btn.removeAttribute("disabled")
      empty_form_info.classList.add("hidden")
    } else {
      if (save_and_new_btn) save_and_new_btn.setAttribute("disabled", "disabled")
      save_btn.setAttribute("disabled", "disabled")
      empty_form_info.classList.remove("hidden")
    }
  }

  //
  // reindex table
  //
  reindexTableRows() {
    const table = document.getElementById(TABLE_ID)
    const rows = table.tBodies[0].querySelectorAll("tr:not(.hidden)")
    for (let i = 0; i < rows.length; i++) {
      let tr = rows[i];
      const index_target = tr.querySelector("span.index")
      index_target.innerHTML = i + 1
    }
  }



  //
  // итого для формы
  //
  setFormTotalHtml() {
    const hotel_form_total_main_target = document.getElementById("hotel_form_total_main_value")
    const hotel_form_total_additional_target = document.getElementById("hotel_form_total_additional_value")
    const totals_hash = this.calculateFormTotal();
    hotel_form_total_main_target.innerHTML = super.setNumericFormat(totals_hash["main"]);
    hotel_form_total_additional_target.innerHTML = super.setNumericFormat(totals_hash["additional"]);
  }

  //
  // расчет итого по строкам таблицы
  //
  calculateFormTotal() {
    const table = document.getElementById(TABLE_ID)
    const rows = table.tBodies[0].rows
    let totals_hash = {
      "main": 0,
      "additional": 0
    }
    for (let i = 0; i < rows.length; i++) {
      let tr = rows[i];
      const status_target = tr.querySelector("select.status_select")

      // не суммируем отмененые
      if (status_target.value != "canceled") {
        let row_totals_hash = this.calculateTrSum(tr);
        totals_hash["main"] += row_totals_hash["total_in_main"]
        totals_hash["additional"] += row_totals_hash["total_in_additional"]
      }
    }
    return totals_hash
  }

  //
  // set tr sum amount
  //
  setTrTotalHtml(tr) {
    // console.log(tr);
    const totals_hash = this.calculateTrSum(tr);
    const total_main_value_html = tr.querySelector(".total_main_value")

    const price_main_span = tr.querySelector("span.price_main_span")
    const price_additional_span = tr.querySelector("span.price_additional_span")
    price_main_span.innerHTML = super.setNumericFormat(totals_hash["price_in_main"]);
    price_additional_span.innerHTML = super.setNumericFormat(totals_hash["price_in_additional"]);

    total_main_value_html.innerHTML = super.setNumericFormat(totals_hash["total_in_main"]);
    const total_additional_value_html = tr.querySelector(".total_additional_value")
    total_additional_value_html.innerHTML = super.setNumericFormat(totals_hash["total_in_additional"]);
  }

  //
  // calculate tr sum amount
  //
  calculateTrSum(tr) {
    const main_additional_rate = tr.getAttribute("data-main-additional-rate").replace(",", '.').replace(/[^\d.-]/g, '');
    const service_currency_to_additional_rate = tr.getAttribute("data-service-currency-to-additional-rate").replace(",", '.').replace(/[^\d.-]/g, '');
    const service_currency_to_main_rate = tr.getAttribute("data-service-currency-to-main-rate").replace(",", '.').replace(/[^\d.-]/g, '');
    const currency_target = tr.querySelector("ul.nav.nav-radio.nav-radio--group input[type='radio']:checked")
    const price_target = tr.querySelector("input.price_input")
    const extra_price_target = tr.querySelector("input.extra_price_input")
    const count_target = tr.querySelector("input.count_input")
    const repeat_count_target = tr.querySelector("input.repeat_count_input")

    let price = parseFloat(price_target.value.replace(",", '.').replace(/[^\d.-]/g, ''))
    if (price_target.numeric) price = parseFloat(price_target.numeric.rawValue.replace(",", '.').replace(/[^\d.-]/g, ''))

    let extra_price = parseFloat(extra_price_target.value.replace(",", '.').replace(/[^\d.-]/g, ''))
    if (extra_price_target.numeric) extra_price = parseFloat(extra_price_target.numeric.rawValue.replace(",", '.').replace(/[^\d.-]/g, ''))

    // let to_main_rate = parseFloat(currency_target.getAttribute("data-rate-to-main").replace(",", '.').replace(/[^\d.-]/g, ''))
    let count = parseFloat(count_target.value.replace(",", '.').replace(/[^\d.-]/g, ''))
    let repeat_count = parseFloat(repeat_count_target.value.replace(",", '.').replace(/[^\d.-]/g, ''))

    price = price || 0
    extra_price = extra_price || 0
    // to_main_rate = to_main_rate || 0
    count = count || 0
    repeat_count = repeat_count || 0

    let main_total = (price + extra_price) * count * repeat_count * service_currency_to_main_rate
    // let additional_total = main_total * main_additional_rate
    let additional_total = (price + extra_price) * count * repeat_count * service_currency_to_additional_rate

    return {
      "total_in_main": main_total,
      "total_in_additional": additional_total,
      "price_in_main": (price + extra_price) * service_currency_to_main_rate,
      "price_in_additional": (price + extra_price) * service_currency_to_additional_rate,
    }
  }


  // add new service to form
  async addServiceRequest(url) {
    let service_input_id = "deal_services_add_new_service_id"
    let service_target = document.getElementById(service_input_id)
    let show_add_popover = this.showAddPopover
    let enable_disable_save_buttons = this.enableDisableSaveButtons
    if (service_target.value) {
      const data = new FormData();
      data.append("room_id", service_target.value);

      await fetch(url, {
        body: data,
        method: "POST",
        dataType: "text/html",
        credentials: "include",
        headers: {
          "X-CSRF-Token": getMetaValue("csrf-token")
        },
      }).then(function (response) {
        if (response.status == 422) {
          response.text().then(function (text) {
            show_add_popover(text)
            enable_disable_save_buttons()
          });
        } else {
          response.text().then(function (text) {
            const table = document.getElementById(TABLE_ID)
            const rows_count = table.tBodies[0].querySelectorAll("tr:not(.hidden)").length + 1;
            let html = text.replace(/NEW_ROW_INDEX/g, rows_count)
            table.tBodies[0].insertAdjacentHTML("beforeend", html);

            enable_disable_save_buttons()
          });
        }
      })
    }
  }


  async reloadServiceRequest(url) {
    let services_table_id = "deal_hotels_form_tbody"
    let table_target = document.getElementById(services_table_id)

    if (table_target) {
      await fetch(url).then( function(response) {
        return response.text()
      }).then( text => {
        table_target.innerHTML = text;
        this.setFormTotalHtml();
      })

    }
  }

  async reloadServiceRowRequest(url, data, tr) {
    const loader = document.querySelector(".ajax_loader.loader")

    if (tr) {
      loader.classList.remove("hidden");

      await fetch(url, {
        body: data,
        method: "POST",
        dataType: "text/html",
        credentials: "include",
        headers: {
          "X-CSRF-Token": getMetaValue("csrf-token")
        },
      }).then( function(response) {
        return response.text()
      }).then( text => {
        tr.outerHTML = text;

        this.reindexTableRows();
        this.setFormTotalHtml();

        loader.classList.add("hidden");
      })
    }
  }

  removeAddPopover() {
    const addBtnTarget = document.getElementById("deal_services_add_new_service_btn")
    if (addBtnTarget.popover) addBtnTarget.popover.dispose()
  }

  showAddPopover(message) {
    const addBtnTarget = document.getElementById("deal_services_add_new_service_btn")
    if (addBtnTarget.popover) addBtnTarget.popover.dispose()

    addBtnTarget.popover = new BSN.Popover(addBtnTarget, {
      template: '<div class="popover" role="tooltip">'
        + '<div class="arrow"></div>'
        + '<h3 class="popover-header"></h3>'
        + '<div class="popover-body">' + message + '</div>'
        + '</div>',
      placement: 'right', //string
      delay: 100, // integer
      dismissible: true,
    });
    addBtnTarget.popover.show()
  }

  // daterange_period
  daterange_period(field, locale_options) {
    let period = null;
    if (field.dataset.period != null) period = field.dataset.period.split("|")
    let min_date = null;
    let max_date = null;
    if (field.dataset.allowable_range != null) [min_date, max_date] = field.dataset.allowable_range.split("|")
    let set_year_limit = this.setYearLimit

    // let is_set_days_count = false
    let days_count_class = field.dataset.daysCountClass;
    let days_count_closest = field.dataset.daysCountClosest;


    const tr = field.closest("tr");
    let hotel_controller = this.hotelController(tr)


    field.flatpickr = flatpickr(field, {
      mode: "range",
      dateFormat: locale_options["date_format"],
      defaultDate: period,
      minDate: min_date,
      maxDate: max_date,
      onChange: function (selectedDates, dateStr, instance) {
        const form_group = field.closest(".form-group")
        const start_at_field = form_group.querySelector("input.start_at")
        const end_at_field = form_group.querySelector("input.end_at")
        let start_at_value = ""
        let end_at_value = ""
        if (selectedDates.length == 0) {
          start_at_value = ""
          end_at_value = ""
        } else if (selectedDates.length == 1) {
          start_at_value = flatpickr.formatDate(selectedDates[0], "Y-m-d")
          end_at_value = flatpickr.formatDate(selectedDates[0], "Y-m-d")
        } else if (selectedDates.length == 2) {
          start_at_value = flatpickr.formatDate(selectedDates[0], "Y-m-d")
          end_at_value = flatpickr.formatDate(selectedDates[1], "Y-m-d")
        }
        if (start_at_field) start_at_field.value = start_at_value
        if (end_at_field) end_at_field.value = end_at_value

        if (days_count_class && days_count_closest) {
          const start_date = new Date(start_at_value).getTime();
          const end_date = new Date(end_at_value).getTime();
          // 1000 milliseconds * 60 minutes * 60 seconds * 24 hours;
          const days_count = Math.ceil((end_date - start_date) / (1000 * 3600 * 24));

          const closest_target = field.closest(days_count_closest)
          const days_count_field = closest_target.querySelector(days_count_class)
          if (days_count_field) {
            if (days_count_field.numeric) days_count_field.numeric.set(days_count)
            else days_count_field.value = days_count

            hotel_controller.setTrTotalHtml(tr)
            hotel_controller.setFormTotalHtml();
          }

        }


      },
      onClose: function (selectedDates, dateStr, instance) {
        if (selectedDates.length == 1) {
          instance.setDate([selectedDates[0], selectedDates[0]], true);
        }
      },
      onOpen: function (selectedDates, dateStr, instance) {
        set_year_limit(instance.currentYearElement)
      }
    });

    let startDate = field.getAttribute("data-start-date")
    let calendarValue = field.getAttribute("value")
    if (startDate != undefined && (calendarValue == '' || calendarValue == undefined )) {
      field.flatpickr.jumpToDate(startDate);
    }
  }


  //
  // Form search choices
  //
  initSupplierChoices() {
    //
    // выбор поставщика, обновляем select для списка услуг
    //
    let remove_add_popover = this.removeAddPopover
    let reload_services = this.getSupplierServicesData
    let reload_suppliers = this.getSuppliersSearchData
    let choice = new Choices(this.choices_supplierTarget, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: true,
      searchChoices: false,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText")
    });
    // set as input attribute
    this.choices_supplierTarget.choices = choice;
    // search
    choice.passedElement.element.addEventListener(
      "search",
      function (event) {
        reload_suppliers(this, event.detail.value, false);
      },
      false,
    );

    // change
    let reload_choices_id = this.choices_supplierTarget.getAttribute("data-reload-target-id")
    if (reload_choices_id) {
      choice.passedElement.element.addEventListener(
        "change",
        function (event) {
          remove_add_popover();
          const reload_el = document.getElementById(reload_choices_id)
          if (reload_el) {
            reload_services(this, event.detail.value);
          }
        },
        false,
      );
    }
    this.addValidateTrigger(this.choices_supplierTarget, this.choices_supplierTarget.choices)
  }

  initServiceChoices() {
    let remove_add_popover = this.removeAddPopover
    let choice = new Choices(this.choices_serviceTarget, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: false,
      shouldSort: false,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText"),
      callbackOnInit: function () {
        if (this.passedElement.element.getAttribute("data-disable") == "true") {
          this.disable();
        }
      }
    });
    // set as input attribute
    this.choices_serviceTarget.choices = choice

    // change
    let btn_id = this.choices_serviceTarget.getAttribute("data-controll-btn")
    choice.passedElement.element.addEventListener(
      "change",
      function (event) {
        const btn = document.getElementById(btn_id)
        if (btn) {
          remove_add_popover();
          if (event.detail.value) {
            btn.classList.remove("disabled")
          } else {
            btn.classList.add("disabled")
          }
        }
      },
      false,
    );
    this.addValidateTrigger(this.choices_serviceTarget, this.choices_serviceTarget.choices)
  }

  //
  // Search Supplier
  //
  async getSuppliersSearchData(target, q) {
    let url = target.getAttribute("data-search-url");
    let choice = target.choices;
    let query = (q == null ? '' : q)
    url += "&q=" + query
    await fetch(url)
      .then(res => res.json())
      .then((data) => {
        choice.setChoices(data, 'value', 'label', true)
      });
  }


  //
  // Supplier services
  //
  async getSupplierServicesData(target, supplier_id) {
    let reload_field_id = target.getAttribute("data-reload-target-id");
    let services_url = target.getAttribute("data-services-url");
    let reload_field = document.getElementById(reload_field_id);

    if (reload_field) {

      let reload_choice = reload_field.choices;
      reload_choice.clearInput();
      reload_choice.clearStore();
      reload_choice.clearChoices();
      reload_choice.setChoiceByValue(null);

      if (supplier_id) {
        reload_choice.setChoices(async () => {
          try {
            const items = await fetch(services_url + "?supplier_id=" + supplier_id);
            return items.json();
          } catch (err) {
            // console.error(err);
          }
        });
        reload_choice.showDropdown();
      }
    }
  }


  messageText(key) {
    let locale = document.body.getAttribute("data-lang");
    const message_list = {
      "ru": {
        loadingText: "Загрузка...",
        noResultsText: "Ничего не найдено",
        noChoicesText: "Нет вариантов выбора",
        itemSelectText: "Нажмите для выбора"
      },
      "en": {
        loadingText: "Loading...",
        noResultsText: "No results found",
        noChoicesText: "No choices to choose from",
        itemSelectText: "Press to select"
      }
    }
    return message_list[locale][key];
  }

  addValidateTrigger(html_element, choices_object) {
    let form, controller, fieldContainer;
    form = html_element.closest('form')
    if (form != undefined)
      controller = this.formController(form)
    if (controller != undefined)
      fieldContainer = html_element.closest('.form-group');

    if (controller != undefined && fieldContainer != undefined) {
      choices_object.passedElement.element.addEventListener(
        'change',
        function (event) {
          controller.checkChoicesMulti(fieldContainer);
        },
        false,
      );
    }
  }

  formController(form_element) {
    return this.application.getControllerForElementAndIdentifier(form_element, "layout--form")
  }

  hotelController(element) {
    return this.application.getControllerForElementAndIdentifier(element, "deals--hotels")
  }

  // yearLimit
  setYearLimit(year_target) {
    if (year_target) {
      year_target.type = "text"
      year_target.pattern = "\d*"
      year_target.maxLength = 4
      year_target.addEventListener(
        "keydown",
        function (event) {
          const charCode = (event.which) ? event.which : event.keyCode
          if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            event.preventDefault();
            return false;
          }
          return true;
        }
      );
    }
  }

}
